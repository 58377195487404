var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline"},[_c('div',{attrs:{"data-aos":"fade-right","data-aos-duration":"2000"}},[_vm._m(0),_c('div',{attrs:{"id":"buttonWrapper"}},[_c('MultiRadioButton',{attrs:{"defaultState":_vm.buttonState,"states":[
          {
            text: 'Guess',
            color: 'nominal',
            name: 'Low',
          },
          {
            text: 'Show All',
            color: 'caution',
            name: 'Medium',
          } ],"callback":_vm.buttonUpdated,"getState":_vm.getState},on:{"click":_vm.changeView}})],1)]),_c('div',{attrs:{"data-aos":"flip-up","data-aos-duration":"2000"}},[_c('section',{attrs:{"id":"mapWrapper"}},[_c('TimelineMap',{attrs:{"showAll":_vm.showAll,"checkClass":_vm.checkClass()}})],1)]),_c('TimelineBlockS')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h1',{attrs:{"id":"title-text"}},[_vm._v("Where he has lead.")]),_c('p',[_vm._v("\"I still love Design as much as when I first heard of the word.\"")])])}]

export { render, staticRenderFns }