import { render, staticRenderFns } from "./multiRadioButton.vue?vue&type=template&id=15c2c52b&scoped=true&"
import script from "./multiRadioButton.vue?vue&type=script&lang=js&"
export * from "./multiRadioButton.vue?vue&type=script&lang=js&"
import style0 from "./multiRadioButton.vue?vue&type=style&index=0&id=15c2c52b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c2c52b",
  null
  
)

export default component.exports